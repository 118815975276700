
@font-face {
  font-family: 'OrbitronMedium';
  src: url('/src/assets/fonts/orbitron/Orbitron-Medium.woff2') format('woff2');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('/src/assets/fonts/roboto/Roboto-Light.woff2') format('woff2');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: 'RobotoThin';
  src: url('/src/assets/fonts/roboto/Roboto-Thin.woff2') format('woff2');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}

@font-face {
  font-family: 'MontserratLight';
  src: url('/src/assets/fonts/montserrat/Montserrat-Light.woff2') format('woff2');
  font-weight: 400; /* Adjust as needed */
  font-style: normal;
}



/* General Styles */
body {
  font-family: 'MontserratLight', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333; /* General text color */
}




h1, h2, h3, h4, h5, h6 {
  font-family: 'OrbitronMedium', sans-serif;
  margin-top: 0;
}

h1 {
  font-size: 1.25rem; /* 40px */
  font-family: 'RobotoThin', sans-serif;

  
}

h2 {
  font-size: 1rem; /* 32px */
  line-height: 1.75rem;

}

h3 {
  font-size: 1.75rem; /* 28px */
  font-weight: bold;
}

h4 {
  font-size: 1.5rem; /* 24px */
  transform: scaleY(1);
  font-weight: bold;
}

h5 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.5rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem; /* 16px */  
}

p {
  font-size: 1rem; /* 16px */
  line-height: 1.6; /* Adjust line-height for readability */
}


/* Header Styles */
.logo {
  font-size: 36px;
  font-weight: bold;
  color: #000; /* Logo color */
}

.text-size-1-5rem {
  font-size: 1.5rem !important;
}